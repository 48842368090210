const audioUtils        = require('./audioUtils');  // for encoding audio data as PCM
const crypto            = require('crypto'); // tot sign our pre-signed URL
const v4                = require('./aws-signature-v4'); // to generate our pre-signed URL
const marshaller        = require("@aws-sdk/eventstream-marshaller"); // for converting binary event stream messages to and from JSON
const util_utf8_node    = require("@aws-sdk/util-utf8-node"); // utilities for encoding and decoding UTF8
const mic               = require('microphone-stream'); // collect microphone input as a stream of raw bytes


(function(){


// our converter between binary event streams messages and JSON
const eventStreamMarshaller = new marshaller.EventStreamMarshaller(util_utf8_node.toUtf8, util_utf8_node.fromUtf8);

class AwsTranscribeLib {

    constructor() {
        this.isAvailable = false;
        this.errorListener = function(){
            //nothing
        };
        this.transcriptListener = function(text, isPartial){
            //nothing
        };
    }

    startTranscribing() {
        // set the language and region from the dropdowns
        setLanguage();
        setRegion();

        const self = this;

        // first we get the microphone input from the browser (as a promise)...
        window.navigator.mediaDevices.getUserMedia({
                video: false,
                audio: true
            })
            // ...then we convert the mic stream to binary event stream messages when the promise resolves 
            .then(streamAudioToWebSocket)
            .catch(function (error) {
                self.errorListener('There was an error streaming your audio to Amazon Transcribe. Please try again.');
            });
    }

    // call when you want to stop recording
    stopTranscribing() {
        if (socket.readyState === socket.OPEN) {
            micStream.stop();

            // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
            let emptyMessage = getAudioEventMessage(Buffer.from(new Buffer([])));
            let emptyBuffer = eventStreamMarshaller.marshall(emptyMessage);
            socket.send(emptyBuffer);
        }
    }

    setListeners(transcriptListener, errorListener) {
        this.errorListener = errorListener;
        this.transcriptListener = transcriptListener;
    }
}

const awsTranscribeLib = new AwsTranscribeLib();
window.awsTranscribeLib = awsTranscribeLib;


/////////////////////////////////////////   DO NOT EDIT ABOVE HERE ////////////////////////////////////////
// CUSTOM GLOBAL VARIABLESMy Access key ID:
let myKey = 'AKIA3SOLIGOA66OWAWP7';
let mySecret = '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';
let myLanguage = 'en-US';
let myRegion = 'ca-central-1';

// Available languages and regions

// en-US
// en-AU
// en-GB
// fr-CA
// fr-FR
// es-US




// us-east-1
// us-east-2
// us-west-2
// ap-southeast-2
// ca-central-1
// eu-west-1


//////////////////////////////////////////   DO NOT EDIT BELOW HERE /////////////////////////////////////////


// check to see if the browser allows mic access
awsTranscribeLib.isAvailable = !!window.navigator.mediaDevices.getUserMedia;

// our global variables for managing state
let languageCode;
let region;
let sampleRate;
let inputSampleRate;
let socket;
let micStream;
let socketError = false;
let transcribeException = false;

let streamAudioToWebSocket = function (userMediaStream) {
    //let's get the mic input from the browser, via the microphone-stream module
    micStream = new mic();

    micStream.on("format", function(data) {
        inputSampleRate = data.sampleRate;
    });

    micStream.setStream(userMediaStream);

    // Pre-signed URLs are a way to authenticate a request (or WebSocket connection, in this case)
    // via Query Parameters. Learn more: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
    let url = createPresignedUrl();

    //open up our WebSocket connection
    socket = new WebSocket(url);
    socket.binaryType = "arraybuffer";

    // when we get audio data from the mic, send it to the WebSocket if possible
    socket.onopen = function() {
        micStream.on('data', function(rawAudioChunk) {
            // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
            let binary = convertAudioToBinaryMessage(rawAudioChunk);

            if (socket.readyState === socket.OPEN)
                socket.send(binary);
        }
    )};

    // handle messages, errors, and close events
    wireSocketEvents();
}

function setLanguage() {
    languageCode = myLanguage;
    if (languageCode === "en-US" || languageCode === "es-US")
        sampleRate = 44100;
    else
        sampleRate = 8000;
}

function setRegion() {
    region = myRegion;
}

function wireSocketEvents() {
    // handle inbound messages from Amazon Transcribe
    socket.onmessage = function (message) {
        //convert the binary event stream message to JSON
        let messageWrapper = eventStreamMarshaller.unmarshall(Buffer(message.data));
        let messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));
        if (messageWrapper.headers[":message-type"].value === "event") {
            handleEventStreamMessage(messageBody);
        }
        else {
            transcribeException = true;
            awsTranscribeLib.errorListener(messageBody.Message);
        }
    };

    socket.onerror = function () {
        socketError = true;
        awsTranscribeLib.errorListener('WebSocket connection error. Try again.');
    };

    socket.onclose = function (closeEvent) {
        micStream.stop();

        // the close event immediately follows the error event; only handle one.
        if (!socketError && !transcribeException) {
            if (closeEvent.code !== 1000) {
                awsTranscribeLib.errorListener('Streaming Exception' + closeEvent.reason);
            }
        }
    };
}

let handleEventStreamMessage = function (messageJson) {
    let results = messageJson.Transcript.Results;

    if (results.length > 0) {
        if (results[0].Alternatives.length > 0) {
            let transcript = results[0].Alternatives[0].Transcript;

            // fix encoding for accented characters
            transcript = decodeURIComponent(escape(transcript));

            // // update the textarea with the latest result
            // $('#transcript').val(transcription + transcript + "\n");

            // // if this transcript segment is final, add it to the overall transcription
            // if (!results[0].IsPartial) {
            //     //scroll the textarea down
            //     $('#transcript').scrollTop($('#transcript')[0].scrollHeight);

            //     transcription += transcript + "\n";
            // }
            awsTranscribeLib.transcriptListener(transcript, results[0].IsPartial);
        }
    }
}

function convertAudioToBinaryMessage(audioChunk) {
    let raw = mic.toRaw(audioChunk);

    if (raw == null)
        return;

    // downsample and convert the raw audio bytes to PCM
    let downsampledBuffer = audioUtils.downsampleBuffer(raw, inputSampleRate, sampleRate);
    let pcmEncodedBuffer = audioUtils.pcmEncode(downsampledBuffer);

    // add the right JSON headers and structure to the message
    let audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer));

    //convert the JSON object + headers into a binary event stream message
    let binary = eventStreamMarshaller.marshall(audioEventMessage);

    return binary;
}

function getAudioEventMessage(buffer) {
    // wrap the audio data in a JSON envelope
    return {
        headers: {
            ':message-type': {
                type: 'string',
                value: 'event'
            },
            ':event-type': {
                type: 'string',
                value: 'AudioEvent'
            }
        },
        body: buffer
    };
}

function createPresignedUrl() {
    let endpoint = "transcribestreaming." + region + ".amazonaws.com:8443";

    // get a preauthenticated URL that we can use to establish our WebSocket
    return v4.createPresignedURL(
        'GET',
        endpoint,
        '/medical-stream-transcription-websocket',
        'transcribe',
        crypto.createHash('sha256').update('', 'utf8').digest('hex'), {
            'key': myKey,
            'secret': mySecret,
            'sessionToken': '',
            'protocol': 'wss',
            'expires': 15,
            'region': region,
            'query': "language-code=" + languageCode + "&media-encoding=pcm&sample-rate=" + sampleRate +"&specialty=PRIMARYCARE&type=DICTATION"
            + "&vocabulary-name=VocabularyTab23"
        }
    );
}

// 'query': "language-code=" + languageCode + "&media-encoding=pcm&sample-rate=" + sampleRate + '&specialty=PRYMARYCARE&type=DICTATION'
})();