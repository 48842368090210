import { Component } from 'react'
import React from 'react'
import { Row, Input, Button, Col } from 'antd'
import ApiManager from '../api/ApiManager'
import Toaster from '../ui/Toaster'

export default class AdminPanel extends Component<
    {},
    {
        retrievedData: string
        apiKey: string
    }
> {
    constructor(props: any) {
        super(props)
        this.state = { retrievedData: '', apiKey: '' }
    }

    fetchData() {
        const self = this
        new ApiManager() //
            .retrieveData(this.state.apiKey)
            .then(function (data) {
                self.setState({ retrievedData: JSON.stringify(data) })
            })
            .catch((err) => Toaster.toast(err))
    }

    render() {
        const self = this
        return (
            <div style={{ padding: 10 }}>
                <Row justify="center">
                    {!self.state.retrievedData && (
                        <Input
                            placeholder={'Admin Panel API Key'}
                            onChange={(e) => {
                                self.setState({ apiKey: `${e.target.value}`.trim() })
                            }}
                        />
                    )}

                    <Button
                        style={{ margin: 20 }}
                        type="primary"
                        onClick={() => {
                            self.fetchData()
                        }}
                    >
                        View Logs
                    </Button>
                </Row>
                {self.state.retrievedData && (
                    <Row justify="center">
                        <Col xs={{ span: 16 }}>
                            <div className="logs-output">{self.state.retrievedData}</div>
                        </Col>
                    </Row>
                )}
            </div>
        )
    }
}
