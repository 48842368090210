import { Component } from 'react'
import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import { Row } from 'antd'

export default class ResultsSection extends Component<
    {
        results: any
    },
    {
        //
    }
> {
    constructor(props: any) {
        super(props)
        this.state = {}
    }

    render() {
        const self = this

        if (self.props.results.isError) {
            return (
                <div style={{ padding: 10 }}>
                    <b> Model error... Please ensure that the input is valid.</b>
                </div>
            )
        }

        const data = [
            {
                name: 'Exacerbation',
                Baseline: Math.ceil(self.props.results.predicted_exac_probability[0] * 100),
                Azithromycin: Math.ceil(self.props.results.azithromycin_predicted_exac_probability[0] * 100)
            },
            {
                name: 'Severe Exacerbation',
                Baseline: Math.ceil(self.props.results.predicted_severe_exac_probability[0] * 100),
                Azithromycin: Math.ceil(self.props.results.azithromycin_predicted_severe_exac_probability[0] * 100)
            }
        ]

        return (
            <div style={{ padding: 10 }}>
                <Row justify="center">
                    <div>
                        <BarChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis label={{ value: 'Probability %', angle: -90 }} />
                            <Tooltip
                                formatter={(value) => {
                                    return value + '%'
                                }}
                            />
                            <Legend />
                            <Bar dataKey="Baseline" fill="#8884d8" />
                            <Bar dataKey="Azithromycin" name="With Azithromycin" fill="#82ca9d" />
                        </BarChart>
                    </div>
                </Row>
            </div>
        )
    }
}
