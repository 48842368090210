export default {
    copyObject<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj)) as T
    },

    isMobile() {
        return window.innerWidth < 768
    },

    scrollDown(px: number) {
        setTimeout(() => {
            window.scrollBy({
                top: px,
                left: 0,
                behavior: 'smooth'
            })
        }, 300)
    },

    getDelayedPromise(time: number) {
        if (!time) {
            return Promise.resolve()
        }

        return new Promise<void>((res, rej) => {
            setTimeout(() => {
                res()
            }, time)
        })
    }
}
