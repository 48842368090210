import Axios from 'axios'
import PrismApiError from './PrismApiError'
import { NlpExportedData, IHashMapGeneric } from './Models'
import Logger from '../utils/Logger'

export default class ApiManager {
    getRandomPatientData(): Promise<IHashMapGeneric<string>> {
        const headers = {} as any
        headers[`x-prism-auth-user`] = `${process.env.REACT_APP_RANDOM_PATIENT_GENERATOR_API_KEY}`
        headers[`Content-Type`] = `application/json`
        return Promise.resolve() //
            .then(function () {
                return Axios.post(
                    `https://prism.peermodelsnetwork.com/route/patientsimulator/run`,
                    { func: ['model_run'] },
                    {
                        headers
                    }
                )
            })
            .then(function (response) {
                return response.data
            })
            .then(function (responseData) {
                if (!!responseData.length) {
                    return JSON.parse(responseData[0])
                }
            })
    }

    getModelResults(inputs: any) {
        const headers = {} as any
        headers[`x-prism-auth-user`] = `${process.env.REACT_APP_MODEL_RUNNER_API_KEY}`
        headers[`Content-Type`] = `application/json`
        return Promise.resolve() //
            .then(function () {
                return Axios.post(
                    `https://prism.peermodelsnetwork.com/route/accept/run`,
                    { func: ['prism_model_run'], model_input: [inputs] },
                    {
                        headers
                    }
                )
            })
            .then(function (response) {
                return response.data
            })
            .then(function (responseData) {
                if (!!responseData.length) {
                    return JSON.parse(responseData[0])
                }
            })
    }

    getDataFromNlpModel(rawText: string, isLongMethod: boolean): Promise<NlpExportedData> {
        const headers = {} as any
        headers[`Content-Type`] = `application/json`
        return Promise.resolve() //
            .then(function () {
                return Axios.post(
                    `${process.env.REACT_APP_URL_TEXT_TO_VALUES_NLP}`,
                    { dictation: rawText, long_method: !!isLongMethod },
                    {
                        headers
                    }
                )
            })
            .then(function (response) {
                return response.data
            })
            .then(function (responseData) {
                if (!responseData || !responseData.body) {
                    Logger.error('Null NLP response! Text: ' + rawText)
                    return {}
                }
                return responseData.body
            })
    }

    storeData(key: string, data: any) {
        const headers = {} as any
        headers[`Content-Type`] = `application/json`
        return Promise.resolve() //
            .then(function () {
                return Axios.post(
                    `${process.env.REACT_APP_API_URL}/api/v1/debug/data`,
                    { key, data },
                    {
                        headers
                    }
                )
            })
            .then(function (response) {
                return response.data
            })
            .then(function (responseData) {
                if (`${responseData.status}` !== '100') {
                    throw new Error('Unexpected status: ' + responseData.status)
                }
                return responseData.body
            })
    }

    retrieveData(apiKey: string) {
        const headers = {} as any
        headers[`Content-Type`] = `application/json`
        headers[`x-prism-auth-admin`] = `${apiKey}`
        return Promise.resolve() //
            .then(function () {
                return Axios.get(`${process.env.REACT_APP_API_URL}/api/v1/debug/data`, {
                    headers
                })
            })
            .then(function (response) {
                return response.data
            })
            .then(function (responseData) {
                if (`${responseData.status}` !== '100') {
                    throw new PrismApiError('Unexpected status: ' + responseData.status)
                }
                return responseData.data
            })
    }
}
