import { Component } from 'react'
import React from 'react'
import { Row, Input, Button } from 'antd'

export default class CommentEntry extends Component<
    {
        onSubmitClicked: (rawText: string) => void
    },
    {
        enteredText: string
    }
> {
    constructor(props: any) {
        super(props)
        this.state = { enteredText: '' }
    }

    render() {
        const self = this
        return (
            <div style={{ padding: 10 }}>
                <Row justify="start">
                    <div style={{ marginBottom: 17, marginTop: 20, marginLeft: 10 }}>
                        <p>
                        Please share any comments or suggestions in the text box below. 
                        Then press the Submit button to finish this case and start with a new patient.
                        </p>
                    </div>
                </Row>
                <Row justify="center">
                    <Input.TextArea
                        value={self.state.enteredText}
                        rows={7}
                        onChange={(e) => {
                            self.setState({ enteredText: e.target.value })
                        }}
                    />
                </Row>
                <div style={{ height: 20 }} />
                <Row justify="end">
                    <Button
                        style={{ minWidth: 150 }}
                        type="primary"
                        size="large"
                        onClick={() => {
                            self.props.onSubmitClicked(self.state.enteredText)
                        }}
                    >
                        Submit and start with a new patient
                    </Button>
                </Row>
            </div>
        )
    }
}
