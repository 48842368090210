import { Component } from 'react'
import React from 'react'
import { Row, Input, Button, Spin, Switch } from 'antd'
import TranscribeService from '../api/TranscribeService'
import Toaster from '../ui/Toaster'

export default class TextEntry extends Component<
    {
        onSubmitClicked: () => void
        onTextChanged: (rawText: string, isLongMethod: boolean) => void
        shouldDisable: boolean
        modelIsFullyIdentified: boolean
    },
    {
        enteredText: string
        isLongMethod: boolean
        enteredTextFinalized: string
        isRecording: boolean
    }
> {
    private transcribeService: TranscribeService
    private lastTimestampNlpStarted: number = 0
    private delayedNlp: (() => void) | undefined = undefined
    private lastTextSentToNlp: string = ''
    private forceSendToNlp = false

    constructor(props: any) {
        super(props)
        this.state = { enteredText: '', enteredTextFinalized: '', isRecording: false, isLongMethod: true }
        this.transcribeService = new TranscribeService(
            (newText: string, isPartial: boolean) => {
                this.updateText(newText, isPartial)
            },
            (error: string) => {
                this.onTranscribeError(error)
            }
        )
    }

    updateText(newText: string, isPartial: boolean) {
        const self = this
        let newTextAfterChange = ''
        if (isPartial) {
            newTextAfterChange = this.state.enteredTextFinalized + '\n' + newText
            this.setState({
                enteredText: newTextAfterChange
            })
        } else {
            newTextAfterChange = this.state.enteredTextFinalized + '\n' + newText
            this.setState({
                enteredTextFinalized: newTextAfterChange,
                enteredText: newTextAfterChange
            })
        }
        self.runNlpWithBackOff()
    }

    runNlpWithBackOff() {
        const self = this
        const MIN_DIFF = 3000
        const now = new Date().getTime()
        if (now - this.lastTimestampNlpStarted < MIN_DIFF) {
            self.delayedNlp = function () {
                self.runNlpImpl()
            }
            return
        }
        self.runNlpImpl()
    }

    runNlpImpl() {
        const self = this
        const text = self.state.enteredTextFinalized
        setTimeout(() => {
            if (!!self.delayedNlp) {
                self.delayedNlp()
            }
        }, 3000)

        const textHasChanged = (self.lastTextSentToNlp || '').trim() !== (text || '').trim()
        const textNotEmpty = !!(text || '').trim()
        if (textNotEmpty && (textHasChanged || self.forceSendToNlp)) {
            self.lastTimestampNlpStarted = new Date().getTime()
            self.props.onTextChanged(text, self.state.isLongMethod)
            self.forceSendToNlp = false
        }

        self.lastTextSentToNlp = text
    }

    onTranscribeError(error: string) {
        Toaster.toast(error)
        this.transcribeService.stopRecording()
    }

    render() {
        const self = this
        return (
            <div style={{ padding: 10 }}>
                <Row justify="start">
                    <div style={{ marginBottom: 5, marginTop: 5, marginLeft: 10 }}>
                        <p>
                            Please dictate the features of the patient that demonstrated in the table above. If you are not happy with what
                            you dictated, please delete the corresponding text. However,{' '}
                            <b>please do not correct the recognized words in this section</b>, even if they are awfully wrong. We would like
                            to record the recognized text. Once you were finished with dictation, you will be given an opportunity to
                            correct the text as you see fit.
                        </p>
                    </div>
                </Row>
                <Row justify="start">
                    <Input.TextArea
                        style={{ opacity: !!self.props.shouldDisable ? 0.5 : 1.0 }}
                        readOnly={!!self.props.shouldDisable}
                        value={self.state.enteredText}
                        rows={9}
                        onChange={(e) => {
                            self.setState({ enteredText: e.target.value, enteredTextFinalized: e.target.value })
                            setTimeout(() => {
                                self.runNlpWithBackOff()
                            }, 500)
                        }}
                    />
                </Row>
                <div style={{ height: 5 }} />
                <Row justify="center">
                    <Switch
                        style={{
                            marginTop: 5,
                            marginRight: 25
                        }}
                        defaultChecked
                        checkedChildren="Long method"
                        unCheckedChildren="Short method"
                        onChange={(checked) => {
                            self.setState({ isLongMethod: checked })
                            self.forceSendToNlp = true
                            setTimeout(() => {
                                self.runNlpWithBackOff()
                            }, 500)
                        }}
                    />
                    <span>
                        <Button
                            disabled={!!self.props.shouldDisable}
                            style={{ minWidth: 150, marginRight: 10 }}
                            type={self.state.isRecording ? `default` : `primary`}
                            onClick={() => {
                                if (self.state.isRecording) {
                                    self.transcribeService.stopRecording()
                                    self.setState({ isRecording: false })
                                } else {
                                    self.transcribeService.startRecording()
                                    self.setState({ isRecording: true })
                                }
                            }}
                        >
                            {self.state.isRecording ? `Stop Recording` : `Start Recording`}
                        </Button>
                        {self.state.isRecording ? <Spin style={{ paddingTop: 5 }} size="small" /> : undefined}
                    </span>
                </Row>
                <div style={{ height: 17 }} />
                <Row justify="end">
                    <Button
                        disabled={!!self.props.shouldDisable || self.state.isRecording || !self.props.modelIsFullyIdentified}
                        style={{ minWidth: 150 }}
                        type="primary"
                        size="large"
                        onClick={() => {
                            self.props.onSubmitClicked()
                        }}
                    >
                        Run Model
                    </Button>
                </Row>
            </div>
        )
    }
}
