import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import * as GlobalActions from '../redux/actions/GlobalActions'
import { connect } from 'react-redux'
import { Row, Col, Button, Spin } from 'antd'
import ApiManager from '../api/ApiManager'
import Toaster from '../ui/Toaster'
import TextEntry from './TextEntry'
import QuestionsList from './QuestionsList'
import { NlpExportedData, IHashMapGeneric } from '../api/Models'
import CommentEntry from './CommentEntry'
import AdminPanel from './AdminPanel'
import ModelExecutor from '../api/ModelExecutor'
import ResultsSection from './ResultsSection'
import Logger from '../utils/Logger'

function makeId(length: number) {
    let result = ''
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

interface RootPageInterface extends RouteComponentProps<any> {
    rootElementKey: string
    emitSizeChanged: () => void
    isMobile: boolean
}

class PageRoot extends Component<
    RootPageInterface,
    {
        collapsed: boolean
        randomPatientData: IHashMapGeneric<string> | undefined
        isLoading: boolean
        isAdminMode: boolean
        nlpData: NlpExportedData
        modelResults: any
        enteredTextFromVoice2Text: string
        textSentToNlp: string
        patientKey: string
    }
> {
    constructor(props: any) {
        super(props)
        this.state = {
            collapsed: false,
            randomPatientData: undefined,
            modelResults: undefined,
            isLoading: true,
            enteredTextFromVoice2Text: '',
            textSentToNlp: '',
            isAdminMode: false,
            nlpData: {},
            patientKey: makeId(10)
        }
    }

    updateDimensions = () => this.props.emitSizeChanged()

    componentWillUnmount() {
        if (super.componentWillUnmount) {
            super.componentWillUnmount()
        }
        this.updateDimensions()
        window.removeEventListener('resize', this.updateDimensions)
    }

    componentDidUpdate(prevProps: any) {
        // Typical usage (don't forget to compare props):
        if (this.props.location.pathname !== prevProps.location.pathname && this.props.isMobile) {
            this.setState({ collapsed: true })
        }
    }

    componentDidMount() {
        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions)
        this.fetchData()
    }

    fetchData() {
        const self = this
        new ApiManager()
            .getRandomPatientData() //
            .then(function (data) {
                self.setState({ randomPatientData: data, patientKey: makeId(10) })
            })
            .catch((err) => Toaster.toast(err))
            .then(function () {
                self.setState({ isLoading: false })
            })
    }

    storeCurrentData(comment: string) {
        const self = this
        return new ApiManager() //
            .storeData(makeId(10), {
                comment,
                patientKey: self.state.patientKey,
                timestamp: new Date().getTime(),
                timestampHumanReadable: new Date().toUTCString(),
                nlpData: self.state.nlpData,
                randomPatientData: self.state.randomPatientData,
                enteredTextFromVoice2Text: self.state.enteredTextFromVoice2Text,
                textSentToNlp: self.state.textSentToNlp
            })
    }

    runModel() {
        const self = this

        return new ModelExecutor(new ApiManager()) //
            .executeModel(self.state.nlpData) //
            .catch((e) => {
                Logger.error(e)
                return { isError: true }
            })
            .then(function (result) {
                if (!result || Object.keys(result).length === 0) {
                    result = { isError: true }
                }
                return { rawNlpData: self.state.nlpData, result }
            })
            .then(function (data) {
                self.setState({
                    nlpData: data.rawNlpData || {},
                    modelResults: data.result
                })
            })
            .catch((err) => {
                Toaster.toast(err)
            })
    }

    runNlp(rawText: string, isLongMethod: boolean) {
        const self = this
        rawText = (rawText || '').trim()
        self.setState({ textSentToNlp: rawText })

        if (!rawText) {
            self.setState({
                nlpData: {}
            })
            return
        }

        new ApiManager()
            .getDataFromNlpModel(rawText, isLongMethod) //
            .then(function (data) {
                return Promise.resolve({ rawNlpData: data })
            })
            .then(function (data) {
                self.setState({
                    nlpData: data.rawNlpData || {}
                })
            })
            .catch((err) => {
                Toaster.toast(err)
            })
    }

    submitData(comment: string) {
        const self = this
        self.setState({ isLoading: true })

        self.storeCurrentData(comment)
            .then(function () {
                setTimeout(() => {
                    window.location.reload(true)
                }, 300)
            })
            .catch((err) => Toaster.toast(err))
            .then(function () {
                self.setState({ isLoading: false })
            })
    }

    render() {
        const self = this

        if (self.state.isLoading) {
            return (
                <div>
                    <div style={{ height: 60 }} />
                    <Row align="middle" justify="center">
                        <span style={{ marginRight: 20 }}> Loading...</span> <Spin />
                    </Row>
                </div>
            )
        }

        if (self.state.isAdminMode) {
            return (
                <div>
                    <Row justify="center">
                        <AdminPanel />
                    </Row>
                </div>
            )
        }

        const randomPatientData = self.state.randomPatientData

        if (!randomPatientData) {
            return (
                <div>
                    <div style={{ height: 60 }} />
                    <Row align="middle" justify="center">
                        Error retrieving data...
                    </Row>
                </div>
            )
        }

        const modelIsFullyIdentified = true
        // if (!self.state.nlpData) {
        //     modelIsFullyIdentified = false
        // } else {
        //     Object.keys(self.state.nlpData).forEach((k) => {
        //         if (`${self.state.nlpData[k][0]}` === '-1') {
        //             modelIsFullyIdentified = false
        //         }
        //     })
        // }

        return (
            <div>
                <Row justify="end">
                    <Button
                        type="dashed"
                        size="small"
                        onClick={() => {
                            self.setState({ isAdminMode: true })
                        }}
                    >
                        Admin Panel
                    </Button>
                </Row>
                <Row justify="start">
                    <Col xs={{ span: 12 }}>
                        <QuestionsList nlpData={self.state.nlpData} randomPatientData={randomPatientData} />
                    </Col>
                    <Col xs={{ span: 12 }}>
                        {self.state.modelResults && Object.keys(self.state.modelResults).length ? (
                            <ResultsSection results={self.state.modelResults} />
                        ) : undefined}
                    </Col>
                </Row>
                <Row justify="center">
                    <Col xs={{ span: 12 }}>
                        <TextEntry
                            shouldDisable={false /*TODO*/}
                            modelIsFullyIdentified={modelIsFullyIdentified}
                            onTextChanged={(rawText, isLongMethod) => {
                                self.setState({ enteredTextFromVoice2Text: rawText })
                                self.runNlp(rawText, isLongMethod)
                            }}
                            onSubmitClicked={() => {
                                self.runModel()
                            }}
                        />
                    </Col>
                    <Col xs={{ span: 12 }}>
                        {self.state.modelResults && Object.keys(self.state.modelResults).length ? (
                            <CommentEntry
                                onSubmitClicked={(comment) => {
                                    self.submitData(comment)
                                }}
                            />
                        ) : undefined}
                    </Col>
                </Row>
                <div style={{ height: 30 }} />
            </div>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        rootElementKey: state.globalReducer.rootElementKey,
        isMobile: state.globalReducer.isMobile
    }
}

export default connect(mapStateToProps, {
    emitSizeChanged: GlobalActions.emitSizeChanged
})(PageRoot)
