import Logger from '../utils/Logger'
import ApiManager from './ApiManager'

export default class ModelExecutor {
    constructor(private apiManager: ApiManager) {}

    getValueFromRaw(raw: any, key: string) {
        try {
            return Number(raw[key][0])
        } catch (err) {
            Logger.error(err)
        }
        return -1
    }

    executeModel(inputsRaw: any) {
        const self = this
        return Promise.resolve() //
            .then(function () {
                const inputs = {
                    randomized_azithromycin: 0,
                    randomized_statin: 0,
                    randomized_LAMA: 0,
                    randomized_LABA: 0,
                    randomized_ICS: 0,
                    random_sampling_N: 1000,
                    random_distribution_iteration: 20000,
                    calculate_CIs: 'FALSE',
                    ID: '10001',
                    // -------------------------
                    male: self.getValueFromRaw(inputsRaw, 'male'),
                    age: self.getValueFromRaw(inputsRaw, 'Age'),
                    smoker: self.getValueFromRaw(inputsRaw, 'smoker'),
                    oxygen: self.getValueFromRaw(inputsRaw, 'oxygen'),
                    statin: self.getValueFromRaw(inputsRaw, 'Statin'),
                    LAMA: self.getValueFromRaw(inputsRaw, 'LAMA'),
                    LABA: self.getValueFromRaw(inputsRaw, 'LABA'),
                    ICS: self.getValueFromRaw(inputsRaw, 'ICS'),
                    FEV1: self.getValueFromRaw(inputsRaw, 'FEV1'),
                    BMI: self.getValueFromRaw(inputsRaw, 'BMI'),
                    SGRQ: self.getValueFromRaw(inputsRaw, 'SGRQ'),
                    LastYrExacCount: self.getValueFromRaw(inputsRaw, 'LastYrExacCount'),
                    LastYrSevExacCount: self.getValueFromRaw(inputsRaw, 'LastYrSevExacCount')
                }

                return self.apiManager.getModelResults(inputs)
            })
    }
}
