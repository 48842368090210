import { Component } from 'react'
import React from 'react'
import { Table } from 'antd'
import { IHashMapGeneric, NlpExportedData } from '../api/Models'
import { ColumnProps } from 'antd/lib/table/Column'

const NOT_DETECTED = 'Not Detected'

const DisplayMapper = [
    {
        key: 'age',
        display: 'Age',
        values: []
    },
    {
        key: 'male',
        display: 'Sex',
        values: [
            {
                from: '1',
                to: 'Male'
            },
            {
                from: '0',
                to: 'Female'
            }
        ]
    },
    {
        key: 'smoker',
        display: 'Smoking Status',
        values: [
            {
                from: '1',
                to: 'Smoker'
            },
            {
                from: '0',
                to: 'Former Smoker'
            }
        ]
    },
    {
        key: 'LastYrExacCount',
        display: 'Exacerbations in the Last Year',
        values: []
    },
    {
        key: 'LastYrSevExacCount',
        display: 'Hospitalizations in the Last Year',
        values: []
    },
    {
        key: 'oxygen',
        display: 'Oxygen Therapy in the Last Year',
        values: []
    },
    {
        key: 'statin',
        display: 'Statin',
        values: []
    },
    {
        key: 'LAMA',
        display: 'LAMA',
        values: []
    },
    {
        key: 'LABA',
        display: 'LABA',
        values: []
    },
    {
        key: 'ICS',
        display: 'ICS',
        values: []
    }
]

DisplayMapper.filter((it) => ['oxygen', 'statin', 'LAMA', 'LABA', 'ICS'].indexOf(it.key) >= 0).forEach((it) => {
    it.values.push({ from: '1', to: 'Yes' })
    it.values.push({ from: '0', to: 'No' })
})

function getDisplayValue(key: string, original: any) {
    if (original === -1 || original === '-1') {
        return NOT_DETECTED
    }

    const mapper = DisplayMapper.filter((it) => it.key === key)[0]

    if (!mapper) {
        return original
    }

    const valueMapper = mapper.values.filter((it) => it.from === `${original}`)[0]

    if (!valueMapper) {
        return original
    }

    return valueMapper.to
}

function getDisplayKey(key: string) {
    const mapper = DisplayMapper.filter((it) => it.key === key)[0]

    if (!mapper) {
        return key
    }

    return mapper.display || key
}

function getRowStyle(row: { hasNlpData: boolean; value: string; key: string; nlpValue: string }) {
    if (!!row.hasNlpData && `${getDisplayValue(row.key, row.nlpValue)}` !== NOT_DETECTED) {
        if (`${row.value[0]}` === `${getDisplayValue(row.key, row.nlpValue)}`) {
            return {
                color: '#009900'
            }
        } else {
            return {
                color: '#ff0000'
            }
        }
    }

    return {}
}

const columns: ColumnProps<any>[] = [
    {
        title: 'Characteristic',
        dataIndex: ['key'],
        render: (key: string, row) => {
            return <div style={getRowStyle(row)}> {getDisplayKey(key)}</div>
        }
    },
    {
        title: 'Patient Chart',
        dataIndex: ['value'],
        render: (value: string[], row) => {
            return (
                <div style={getRowStyle(row)}>
                    <b>{getDisplayValue(row.key, value[0])}</b>
                </div>
            )
        }
    },
    {
        title: 'Detected by AI',
        dataIndex: ['nlpValue'],
        render: (value: string, row) => {
            return (
                <div style={getRowStyle(row)}>
                    <b>{getDisplayValue(row.key, value)}</b>
                </div>
            )
        }
    }
]

export default class QuestionsList extends Component<
    {
        nlpData: NlpExportedData
        randomPatientData: IHashMapGeneric<string>
    },
    {}
> {
    constructor(props: any) {
        super(props)
        this.state = {}
    }

    render() {
        const self = this
        const nlpDataAllCaps = {} as any
        Object.keys(self.props.nlpData).forEach((k) => {
            nlpDataAllCaps[k.toUpperCase()] = self.props.nlpData[k]
        })

        const hasNlpData = Object.keys(self.props.nlpData).length > 0

        const randomPatientData = this.props.randomPatientData
        const questions = [] as any[]
        Object.keys(randomPatientData).forEach((k) => {
            questions.push({
                key: k,
                value: randomPatientData[k],
                nlpValue: nlpDataAllCaps[k.toUpperCase()] ? nlpDataAllCaps[k.toUpperCase()][0] : undefined,
                hasNlpData
            })
        })

        const keysInOrder = 'age/male/bmi/smoker/oxygen/statin/LABA/LAMA/ICS/FEV1/SGRQ/LastYrExacCount/LastYrSevExacCount'
            .toLowerCase()
            .split('/')
            .reverse()

        questions.sort((a, b) => {
            return keysInOrder.indexOf(`${b.key}`.toLowerCase()) - keysInOrder.indexOf(`${a.key}`.toLowerCase())
        })

        return (
            <div style={{ padding: 10 }}>
                <Table columns={columns} pagination={{ hideOnSinglePage: true, pageSize: 999 }} dataSource={questions} />
            </div>
        )
    }
}
