require('./aws/aws-transcribe-service')

const awsTranscribeLib = (window as any).awsTranscribeLib

export default class TranscribeService {
    constructor(transcribeListener: any, errorListener: any) {
        awsTranscribeLib.setListeners(transcribeListener, errorListener)
    }

    startRecording() {
        awsTranscribeLib.startTranscribing()
    }

    stopRecording() {
        awsTranscribeLib.stopTranscribing()
    }
}
